.command {
	background-color: #222a35;
	> .content {
		padding: 10px;
		> * {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
		box-sizing: border-box;
		> *,
		> * > *,
		> * > * > * {
			box-sizing: border-box;
		}
		.buttons {
			background-color: #222a35;

			> button.send {
				width: 100%;
				padding: 10px;
				> b {
					margin-left: 5px;
				}
			}
		}
	}
}
