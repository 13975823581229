.settings {
	> .content {
		border-top: 1px dashed rgba(255,255,255,0.3);
		padding: 10px;
		background-color: $darkBlue1;
		button {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 5px;
			label {
				margin-bottom: 10px;
			}
		}
	}
}