.topBar {
  display: fixed;
	position: relative;
  top: 0;
  width: 100%;
  padding: 10px;
  background-color: $darkBlue1;
  .menuWrapper {
		position: absolute;
		z-index: 1000;
    width: 100%;
    margin-left: -10px;
    bottom: 0;
    color: aliceblue;
    .topMenu {
      position: absolute;
      background-color: $darkBlue2;
			width: 100%;
			transition: all 0.3s ease;
			box-shadow: 0 10px 10px 0px #111010, inset 0 1px 0px 1px #324b5e;
			>.content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				.menuItem {
					flex: 1;
					background-color: rgba(255,255,255,0.05);
					margin: 4px 0;
				}
				a, label {
					cursor: pointer;
					color: aliceblue;
					font-weight: bold;
				}
				a:active, a:link {
					
				}
				a:hover, a:hover label {
					color: rgb(106, 163, 209);
				}
				padding-bottom: 53px;
			} //content
      .btnHide {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
      &.visible {
        min-height: 100px;
      }
      &.hidden {
        min-height: 0px;
        pointer-events: none;
        .btnHide, .content {
          display: none;
        }
      }
    }
  }
}
