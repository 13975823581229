$white: rgb(227, 247, 250);
$white2: rgb(182, 207, 211);
$darkBlue1: rgb(34, 42, 53);
$darkBlue2: rgba(56, 70, 82, 1);
$yellow1: #f5a623;
$red1: rgb(224, 61, 61);
$red2: rgb(255, 124, 124);
$green1: limegreen;
$light01: rgba(255, 255, 255, 0.1);
$light03: rgba(255, 255, 255, 0.3);
$light05: rgba(255, 255, 255, 0.5);
