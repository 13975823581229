.contest {
  background-color: $darkBlue2;
  > .content {
    .important {
      font-size: 22px;
      line-height: 0px;
      color: $red1;
    }
    max-width: 960px;
    margin: auto;
    background-color: $darkBlue1;
    padding: 10px;
    > .dbs {
      border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
      padding-bottom: 10px;
    }
    > .contestCreate {
      > .block,
      > .time > .block {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        > label {
          display: block;
          margin-bottom: 5px;
        }
        > .gamesSelected {
          padding: 10px;
          background-color: $darkBlue2;
          border-radius: 5px;
          label {
            color: $red2;
          }
        }
        > .hide {
          display: none;
        }
      }
      > .time > .block {
        margin-right: 10px;
      }
    }
    > .contestList {
      > .list {
        display: flex;
        flex-wrap: wrap;
        > .contestItem {
          margin: 5px;
          background-color: $darkBlue2;
          border-radius: 5px;
          padding: 5px;
          box-shadow: 0 0 1px 3px #111010, inset 0 1px 0px 1px #324b5e;
          > .caption {
            overflow: hidden;
            margin-bottom: 5px;
            display: block;
          }
          > .price {
            color: $green1;
            margin-bottom: 5px;
          }
          > .img {
            height: 100px;
            width: 200px;
            background-size: cover;
            background-position: center;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            margin-bottom: 5px;
          }
          > .range {
            background-color: $darkBlue1;
            padding: 5px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            > .time {
              font-size: 12px;
              color: $white2;
              > u,
              > i {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
