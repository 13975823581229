@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@-webkit-keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

div,
input,
textarea,
button,
label,
ul,
li,
span {
  box-sizing: border-box;
  font: inherit;
  font-family: "Roboto Mono", monospace; }

* {
  padding: 0 0 0 0;
  margin: 0 0 0 0; }

*:focus {
  outline: none; }

.break_all {
  word-break: break-all; }

.break_word {
  word-break: break-word; }

.keep_all {
  word-break: keep-all; }

.fade_30 {
  opacity: 0.3;
  pointer-events: none; }

.fade_50 {
  opacity: 0.5;
  pointer-events: none; }

.flex_h {
  display: flex;
  flex-direction: row; }

.flex_v {
  display: flex;
  flex-direction: column; }

.flex1 {
  flex: 1 1; }

.flex_c_c {
  align-items: center;
  justify-content: center; }

.flex_c_0 {
  align-items: center;
  justify-content: flex-start; }

.flex_c_1 {
  align-items: center;
  justify-content: flex-end; }

.flex_0_c {
  align-items: flex-start;
  justify-content: center; }

.flex_0_0 {
  align-items: flex-start;
  justify-content: flex-start; }

.flex_0_1 {
  align-items: flex-start;
  justify-content: flex-end; }

.flex_1_c {
  align-items: flex-end;
  justify-content: center; }

.flex_1_0 {
  align-items: flex-end;
  justify-content: flex-start; }

.flex_1_1 {
  align-items: flex-end;
  justify-content: flex-end; }

.flex_c_b {
  align-items: center;
  justify-content: space-between; }

.flex_c_a {
  align-items: center;
  justify-content: space-around; }

.flex_c_1_i {
  align-items: center !important;
  justify-content: flex-end !important; }

.flex_wrap {
  flex-wrap: wrap; }

.flex_a_0 {
  align-self: flex-start; }

.flex_a_1 {
  align-self: flex-end; }

.grid {
  display: grid; }

.l {
  text-align: left; }

.c {
  text-align: center; }

.r {
  text-align: right; }

h1,
h2,
h3,
h4,
h5 {
  padding: 0 0;
  margin: 0 0;
  text-transform: uppercase; }

.f5 {
  font-size: 5px; }

.m5_all {
  margin: 5px; }

.m5_l {
  margin-left: 5px; }

.m5_r {
  margin-right: 5px; }

.m5_t {
  margin-top: 5px; }

.m5_b {
  margin-bottom: 5px; }

.p5_all {
  padding: 5px; }

.p5_l {
  padding-left: 5px; }

.p5_r {
  padding-right: 5px; }

.p5_t {
  padding-top: 5px; }

.p5_b {
  padding-bottom: 5px; }

.f6 {
  font-size: 6px; }

.m6_all {
  margin: 6px; }

.m6_l {
  margin-left: 6px; }

.m6_r {
  margin-right: 6px; }

.m6_t {
  margin-top: 6px; }

.m6_b {
  margin-bottom: 6px; }

.p6_all {
  padding: 6px; }

.p6_l {
  padding-left: 6px; }

.p6_r {
  padding-right: 6px; }

.p6_t {
  padding-top: 6px; }

.p6_b {
  padding-bottom: 6px; }

.f7 {
  font-size: 7px; }

.m7_all {
  margin: 7px; }

.m7_l {
  margin-left: 7px; }

.m7_r {
  margin-right: 7px; }

.m7_t {
  margin-top: 7px; }

.m7_b {
  margin-bottom: 7px; }

.p7_all {
  padding: 7px; }

.p7_l {
  padding-left: 7px; }

.p7_r {
  padding-right: 7px; }

.p7_t {
  padding-top: 7px; }

.p7_b {
  padding-bottom: 7px; }

.f8 {
  font-size: 8px; }

.m8_all {
  margin: 8px; }

.m8_l {
  margin-left: 8px; }

.m8_r {
  margin-right: 8px; }

.m8_t {
  margin-top: 8px; }

.m8_b {
  margin-bottom: 8px; }

.p8_all {
  padding: 8px; }

.p8_l {
  padding-left: 8px; }

.p8_r {
  padding-right: 8px; }

.p8_t {
  padding-top: 8px; }

.p8_b {
  padding-bottom: 8px; }

.f9 {
  font-size: 9px; }

.m9_all {
  margin: 9px; }

.m9_l {
  margin-left: 9px; }

.m9_r {
  margin-right: 9px; }

.m9_t {
  margin-top: 9px; }

.m9_b {
  margin-bottom: 9px; }

.p9_all {
  padding: 9px; }

.p9_l {
  padding-left: 9px; }

.p9_r {
  padding-right: 9px; }

.p9_t {
  padding-top: 9px; }

.p9_b {
  padding-bottom: 9px; }

.f10 {
  font-size: 10px; }

.m10_all {
  margin: 10px; }

.m10_l {
  margin-left: 10px; }

.m10_r {
  margin-right: 10px; }

.m10_t {
  margin-top: 10px; }

.m10_b {
  margin-bottom: 10px; }

.p10_all {
  padding: 10px; }

.p10_l {
  padding-left: 10px; }

.p10_r {
  padding-right: 10px; }

.p10_t {
  padding-top: 10px; }

.p10_b {
  padding-bottom: 10px; }

.f11 {
  font-size: 11px; }

.m11_all {
  margin: 11px; }

.m11_l {
  margin-left: 11px; }

.m11_r {
  margin-right: 11px; }

.m11_t {
  margin-top: 11px; }

.m11_b {
  margin-bottom: 11px; }

.p11_all {
  padding: 11px; }

.p11_l {
  padding-left: 11px; }

.p11_r {
  padding-right: 11px; }

.p11_t {
  padding-top: 11px; }

.p11_b {
  padding-bottom: 11px; }

.f12 {
  font-size: 12px; }

.m12_all {
  margin: 12px; }

.m12_l {
  margin-left: 12px; }

.m12_r {
  margin-right: 12px; }

.m12_t {
  margin-top: 12px; }

.m12_b {
  margin-bottom: 12px; }

.p12_all {
  padding: 12px; }

.p12_l {
  padding-left: 12px; }

.p12_r {
  padding-right: 12px; }

.p12_t {
  padding-top: 12px; }

.p12_b {
  padding-bottom: 12px; }

.f13 {
  font-size: 13px; }

.m13_all {
  margin: 13px; }

.m13_l {
  margin-left: 13px; }

.m13_r {
  margin-right: 13px; }

.m13_t {
  margin-top: 13px; }

.m13_b {
  margin-bottom: 13px; }

.p13_all {
  padding: 13px; }

.p13_l {
  padding-left: 13px; }

.p13_r {
  padding-right: 13px; }

.p13_t {
  padding-top: 13px; }

.p13_b {
  padding-bottom: 13px; }

.f14 {
  font-size: 14px; }

.m14_all {
  margin: 14px; }

.m14_l {
  margin-left: 14px; }

.m14_r {
  margin-right: 14px; }

.m14_t {
  margin-top: 14px; }

.m14_b {
  margin-bottom: 14px; }

.p14_all {
  padding: 14px; }

.p14_l {
  padding-left: 14px; }

.p14_r {
  padding-right: 14px; }

.p14_t {
  padding-top: 14px; }

.p14_b {
  padding-bottom: 14px; }

.f15 {
  font-size: 15px; }

.m15_all {
  margin: 15px; }

.m15_l {
  margin-left: 15px; }

.m15_r {
  margin-right: 15px; }

.m15_t {
  margin-top: 15px; }

.m15_b {
  margin-bottom: 15px; }

.p15_all {
  padding: 15px; }

.p15_l {
  padding-left: 15px; }

.p15_r {
  padding-right: 15px; }

.p15_t {
  padding-top: 15px; }

.p15_b {
  padding-bottom: 15px; }

.f16 {
  font-size: 16px; }

.m16_all {
  margin: 16px; }

.m16_l {
  margin-left: 16px; }

.m16_r {
  margin-right: 16px; }

.m16_t {
  margin-top: 16px; }

.m16_b {
  margin-bottom: 16px; }

.p16_all {
  padding: 16px; }

.p16_l {
  padding-left: 16px; }

.p16_r {
  padding-right: 16px; }

.p16_t {
  padding-top: 16px; }

.p16_b {
  padding-bottom: 16px; }

.f17 {
  font-size: 17px; }

.m17_all {
  margin: 17px; }

.m17_l {
  margin-left: 17px; }

.m17_r {
  margin-right: 17px; }

.m17_t {
  margin-top: 17px; }

.m17_b {
  margin-bottom: 17px; }

.p17_all {
  padding: 17px; }

.p17_l {
  padding-left: 17px; }

.p17_r {
  padding-right: 17px; }

.p17_t {
  padding-top: 17px; }

.p17_b {
  padding-bottom: 17px; }

.f18 {
  font-size: 18px; }

.m18_all {
  margin: 18px; }

.m18_l {
  margin-left: 18px; }

.m18_r {
  margin-right: 18px; }

.m18_t {
  margin-top: 18px; }

.m18_b {
  margin-bottom: 18px; }

.p18_all {
  padding: 18px; }

.p18_l {
  padding-left: 18px; }

.p18_r {
  padding-right: 18px; }

.p18_t {
  padding-top: 18px; }

.p18_b {
  padding-bottom: 18px; }

.f19 {
  font-size: 19px; }

.m19_all {
  margin: 19px; }

.m19_l {
  margin-left: 19px; }

.m19_r {
  margin-right: 19px; }

.m19_t {
  margin-top: 19px; }

.m19_b {
  margin-bottom: 19px; }

.p19_all {
  padding: 19px; }

.p19_l {
  padding-left: 19px; }

.p19_r {
  padding-right: 19px; }

.p19_t {
  padding-top: 19px; }

.p19_b {
  padding-bottom: 19px; }

.f20 {
  font-size: 20px; }

.m20_all {
  margin: 20px; }

.m20_l {
  margin-left: 20px; }

.m20_r {
  margin-right: 20px; }

.m20_t {
  margin-top: 20px; }

.m20_b {
  margin-bottom: 20px; }

.p20_all {
  padding: 20px; }

.p20_l {
  padding-left: 20px; }

.p20_r {
  padding-right: 20px; }

.p20_t {
  padding-top: 20px; }

.p20_b {
  padding-bottom: 20px; }

.f21 {
  font-size: 21px; }

.m21_all {
  margin: 21px; }

.m21_l {
  margin-left: 21px; }

.m21_r {
  margin-right: 21px; }

.m21_t {
  margin-top: 21px; }

.m21_b {
  margin-bottom: 21px; }

.p21_all {
  padding: 21px; }

.p21_l {
  padding-left: 21px; }

.p21_r {
  padding-right: 21px; }

.p21_t {
  padding-top: 21px; }

.p21_b {
  padding-bottom: 21px; }

.f22 {
  font-size: 22px; }

.m22_all {
  margin: 22px; }

.m22_l {
  margin-left: 22px; }

.m22_r {
  margin-right: 22px; }

.m22_t {
  margin-top: 22px; }

.m22_b {
  margin-bottom: 22px; }

.p22_all {
  padding: 22px; }

.p22_l {
  padding-left: 22px; }

.p22_r {
  padding-right: 22px; }

.p22_t {
  padding-top: 22px; }

.p22_b {
  padding-bottom: 22px; }

.f23 {
  font-size: 23px; }

.m23_all {
  margin: 23px; }

.m23_l {
  margin-left: 23px; }

.m23_r {
  margin-right: 23px; }

.m23_t {
  margin-top: 23px; }

.m23_b {
  margin-bottom: 23px; }

.p23_all {
  padding: 23px; }

.p23_l {
  padding-left: 23px; }

.p23_r {
  padding-right: 23px; }

.p23_t {
  padding-top: 23px; }

.p23_b {
  padding-bottom: 23px; }

.f24 {
  font-size: 24px; }

.m24_all {
  margin: 24px; }

.m24_l {
  margin-left: 24px; }

.m24_r {
  margin-right: 24px; }

.m24_t {
  margin-top: 24px; }

.m24_b {
  margin-bottom: 24px; }

.p24_all {
  padding: 24px; }

.p24_l {
  padding-left: 24px; }

.p24_r {
  padding-right: 24px; }

.p24_t {
  padding-top: 24px; }

.p24_b {
  padding-bottom: 24px; }

.f25 {
  font-size: 25px; }

.m25_all {
  margin: 25px; }

.m25_l {
  margin-left: 25px; }

.m25_r {
  margin-right: 25px; }

.m25_t {
  margin-top: 25px; }

.m25_b {
  margin-bottom: 25px; }

.p25_all {
  padding: 25px; }

.p25_l {
  padding-left: 25px; }

.p25_r {
  padding-right: 25px; }

.p25_t {
  padding-top: 25px; }

.p25_b {
  padding-bottom: 25px; }

.f26 {
  font-size: 26px; }

.m26_all {
  margin: 26px; }

.m26_l {
  margin-left: 26px; }

.m26_r {
  margin-right: 26px; }

.m26_t {
  margin-top: 26px; }

.m26_b {
  margin-bottom: 26px; }

.p26_all {
  padding: 26px; }

.p26_l {
  padding-left: 26px; }

.p26_r {
  padding-right: 26px; }

.p26_t {
  padding-top: 26px; }

.p26_b {
  padding-bottom: 26px; }

.f27 {
  font-size: 27px; }

.m27_all {
  margin: 27px; }

.m27_l {
  margin-left: 27px; }

.m27_r {
  margin-right: 27px; }

.m27_t {
  margin-top: 27px; }

.m27_b {
  margin-bottom: 27px; }

.p27_all {
  padding: 27px; }

.p27_l {
  padding-left: 27px; }

.p27_r {
  padding-right: 27px; }

.p27_t {
  padding-top: 27px; }

.p27_b {
  padding-bottom: 27px; }

.f28 {
  font-size: 28px; }

.m28_all {
  margin: 28px; }

.m28_l {
  margin-left: 28px; }

.m28_r {
  margin-right: 28px; }

.m28_t {
  margin-top: 28px; }

.m28_b {
  margin-bottom: 28px; }

.p28_all {
  padding: 28px; }

.p28_l {
  padding-left: 28px; }

.p28_r {
  padding-right: 28px; }

.p28_t {
  padding-top: 28px; }

.p28_b {
  padding-bottom: 28px; }

.f29 {
  font-size: 29px; }

.m29_all {
  margin: 29px; }

.m29_l {
  margin-left: 29px; }

.m29_r {
  margin-right: 29px; }

.m29_t {
  margin-top: 29px; }

.m29_b {
  margin-bottom: 29px; }

.p29_all {
  padding: 29px; }

.p29_l {
  padding-left: 29px; }

.p29_r {
  padding-right: 29px; }

.p29_t {
  padding-top: 29px; }

.p29_b {
  padding-bottom: 29px; }

.f30 {
  font-size: 30px; }

.m30_all {
  margin: 30px; }

.m30_l {
  margin-left: 30px; }

.m30_r {
  margin-right: 30px; }

.m30_t {
  margin-top: 30px; }

.m30_b {
  margin-bottom: 30px; }

.p30_all {
  padding: 30px; }

.p30_l {
  padding-left: 30px; }

.p30_r {
  padding-right: 30px; }

.p30_t {
  padding-top: 30px; }

.p30_b {
  padding-bottom: 30px; }

.f31 {
  font-size: 31px; }

.m31_all {
  margin: 31px; }

.m31_l {
  margin-left: 31px; }

.m31_r {
  margin-right: 31px; }

.m31_t {
  margin-top: 31px; }

.m31_b {
  margin-bottom: 31px; }

.p31_all {
  padding: 31px; }

.p31_l {
  padding-left: 31px; }

.p31_r {
  padding-right: 31px; }

.p31_t {
  padding-top: 31px; }

.p31_b {
  padding-bottom: 31px; }

.f32 {
  font-size: 32px; }

.m32_all {
  margin: 32px; }

.m32_l {
  margin-left: 32px; }

.m32_r {
  margin-right: 32px; }

.m32_t {
  margin-top: 32px; }

.m32_b {
  margin-bottom: 32px; }

.p32_all {
  padding: 32px; }

.p32_l {
  padding-left: 32px; }

.p32_r {
  padding-right: 32px; }

.p32_t {
  padding-top: 32px; }

.p32_b {
  padding-bottom: 32px; }

.f33 {
  font-size: 33px; }

.m33_all {
  margin: 33px; }

.m33_l {
  margin-left: 33px; }

.m33_r {
  margin-right: 33px; }

.m33_t {
  margin-top: 33px; }

.m33_b {
  margin-bottom: 33px; }

.p33_all {
  padding: 33px; }

.p33_l {
  padding-left: 33px; }

.p33_r {
  padding-right: 33px; }

.p33_t {
  padding-top: 33px; }

.p33_b {
  padding-bottom: 33px; }

.f34 {
  font-size: 34px; }

.m34_all {
  margin: 34px; }

.m34_l {
  margin-left: 34px; }

.m34_r {
  margin-right: 34px; }

.m34_t {
  margin-top: 34px; }

.m34_b {
  margin-bottom: 34px; }

.p34_all {
  padding: 34px; }

.p34_l {
  padding-left: 34px; }

.p34_r {
  padding-right: 34px; }

.p34_t {
  padding-top: 34px; }

.p34_b {
  padding-bottom: 34px; }

.f35 {
  font-size: 35px; }

.m35_all {
  margin: 35px; }

.m35_l {
  margin-left: 35px; }

.m35_r {
  margin-right: 35px; }

.m35_t {
  margin-top: 35px; }

.m35_b {
  margin-bottom: 35px; }

.p35_all {
  padding: 35px; }

.p35_l {
  padding-left: 35px; }

.p35_r {
  padding-right: 35px; }

.p35_t {
  padding-top: 35px; }

.p35_b {
  padding-bottom: 35px; }

.f36 {
  font-size: 36px; }

.m36_all {
  margin: 36px; }

.m36_l {
  margin-left: 36px; }

.m36_r {
  margin-right: 36px; }

.m36_t {
  margin-top: 36px; }

.m36_b {
  margin-bottom: 36px; }

.p36_all {
  padding: 36px; }

.p36_l {
  padding-left: 36px; }

.p36_r {
  padding-right: 36px; }

.p36_t {
  padding-top: 36px; }

.p36_b {
  padding-bottom: 36px; }

.f37 {
  font-size: 37px; }

.m37_all {
  margin: 37px; }

.m37_l {
  margin-left: 37px; }

.m37_r {
  margin-right: 37px; }

.m37_t {
  margin-top: 37px; }

.m37_b {
  margin-bottom: 37px; }

.p37_all {
  padding: 37px; }

.p37_l {
  padding-left: 37px; }

.p37_r {
  padding-right: 37px; }

.p37_t {
  padding-top: 37px; }

.p37_b {
  padding-bottom: 37px; }

.f38 {
  font-size: 38px; }

.m38_all {
  margin: 38px; }

.m38_l {
  margin-left: 38px; }

.m38_r {
  margin-right: 38px; }

.m38_t {
  margin-top: 38px; }

.m38_b {
  margin-bottom: 38px; }

.p38_all {
  padding: 38px; }

.p38_l {
  padding-left: 38px; }

.p38_r {
  padding-right: 38px; }

.p38_t {
  padding-top: 38px; }

.p38_b {
  padding-bottom: 38px; }

.f39 {
  font-size: 39px; }

.m39_all {
  margin: 39px; }

.m39_l {
  margin-left: 39px; }

.m39_r {
  margin-right: 39px; }

.m39_t {
  margin-top: 39px; }

.m39_b {
  margin-bottom: 39px; }

.p39_all {
  padding: 39px; }

.p39_l {
  padding-left: 39px; }

.p39_r {
  padding-right: 39px; }

.p39_t {
  padding-top: 39px; }

.p39_b {
  padding-bottom: 39px; }

.f40 {
  font-size: 40px; }

.m40_all {
  margin: 40px; }

.m40_l {
  margin-left: 40px; }

.m40_r {
  margin-right: 40px; }

.m40_t {
  margin-top: 40px; }

.m40_b {
  margin-bottom: 40px; }

.p40_all {
  padding: 40px; }

.p40_l {
  padding-left: 40px; }

.p40_r {
  padding-right: 40px; }

.p40_t {
  padding-top: 40px; }

.p40_b {
  padding-bottom: 40px; }

.f41 {
  font-size: 41px; }

.m41_all {
  margin: 41px; }

.m41_l {
  margin-left: 41px; }

.m41_r {
  margin-right: 41px; }

.m41_t {
  margin-top: 41px; }

.m41_b {
  margin-bottom: 41px; }

.p41_all {
  padding: 41px; }

.p41_l {
  padding-left: 41px; }

.p41_r {
  padding-right: 41px; }

.p41_t {
  padding-top: 41px; }

.p41_b {
  padding-bottom: 41px; }

.f42 {
  font-size: 42px; }

.m42_all {
  margin: 42px; }

.m42_l {
  margin-left: 42px; }

.m42_r {
  margin-right: 42px; }

.m42_t {
  margin-top: 42px; }

.m42_b {
  margin-bottom: 42px; }

.p42_all {
  padding: 42px; }

.p42_l {
  padding-left: 42px; }

.p42_r {
  padding-right: 42px; }

.p42_t {
  padding-top: 42px; }

.p42_b {
  padding-bottom: 42px; }

.f43 {
  font-size: 43px; }

.m43_all {
  margin: 43px; }

.m43_l {
  margin-left: 43px; }

.m43_r {
  margin-right: 43px; }

.m43_t {
  margin-top: 43px; }

.m43_b {
  margin-bottom: 43px; }

.p43_all {
  padding: 43px; }

.p43_l {
  padding-left: 43px; }

.p43_r {
  padding-right: 43px; }

.p43_t {
  padding-top: 43px; }

.p43_b {
  padding-bottom: 43px; }

.f44 {
  font-size: 44px; }

.m44_all {
  margin: 44px; }

.m44_l {
  margin-left: 44px; }

.m44_r {
  margin-right: 44px; }

.m44_t {
  margin-top: 44px; }

.m44_b {
  margin-bottom: 44px; }

.p44_all {
  padding: 44px; }

.p44_l {
  padding-left: 44px; }

.p44_r {
  padding-right: 44px; }

.p44_t {
  padding-top: 44px; }

.p44_b {
  padding-bottom: 44px; }

.f45 {
  font-size: 45px; }

.m45_all {
  margin: 45px; }

.m45_l {
  margin-left: 45px; }

.m45_r {
  margin-right: 45px; }

.m45_t {
  margin-top: 45px; }

.m45_b {
  margin-bottom: 45px; }

.p45_all {
  padding: 45px; }

.p45_l {
  padding-left: 45px; }

.p45_r {
  padding-right: 45px; }

.p45_t {
  padding-top: 45px; }

.p45_b {
  padding-bottom: 45px; }

.f46 {
  font-size: 46px; }

.m46_all {
  margin: 46px; }

.m46_l {
  margin-left: 46px; }

.m46_r {
  margin-right: 46px; }

.m46_t {
  margin-top: 46px; }

.m46_b {
  margin-bottom: 46px; }

.p46_all {
  padding: 46px; }

.p46_l {
  padding-left: 46px; }

.p46_r {
  padding-right: 46px; }

.p46_t {
  padding-top: 46px; }

.p46_b {
  padding-bottom: 46px; }

.f47 {
  font-size: 47px; }

.m47_all {
  margin: 47px; }

.m47_l {
  margin-left: 47px; }

.m47_r {
  margin-right: 47px; }

.m47_t {
  margin-top: 47px; }

.m47_b {
  margin-bottom: 47px; }

.p47_all {
  padding: 47px; }

.p47_l {
  padding-left: 47px; }

.p47_r {
  padding-right: 47px; }

.p47_t {
  padding-top: 47px; }

.p47_b {
  padding-bottom: 47px; }

.f48 {
  font-size: 48px; }

.m48_all {
  margin: 48px; }

.m48_l {
  margin-left: 48px; }

.m48_r {
  margin-right: 48px; }

.m48_t {
  margin-top: 48px; }

.m48_b {
  margin-bottom: 48px; }

.p48_all {
  padding: 48px; }

.p48_l {
  padding-left: 48px; }

.p48_r {
  padding-right: 48px; }

.p48_t {
  padding-top: 48px; }

.p48_b {
  padding-bottom: 48px; }

.f49 {
  font-size: 49px; }

.m49_all {
  margin: 49px; }

.m49_l {
  margin-left: 49px; }

.m49_r {
  margin-right: 49px; }

.m49_t {
  margin-top: 49px; }

.m49_b {
  margin-bottom: 49px; }

.p49_all {
  padding: 49px; }

.p49_l {
  padding-left: 49px; }

.p49_r {
  padding-right: 49px; }

.p49_t {
  padding-top: 49px; }

.p49_b {
  padding-bottom: 49px; }

.h50 {
  height: 50%; }

.w50 {
  width: 50%; }

.h100 {
  height: 100%; }

.w100 {
  width: 100%; }

.w100vw {
  width: 100vw; }

.h100vh {
  height: 100vh; }

.w320px {
  width: 320px; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.scale08 {
  -webkit-transform: scale(0.8);
          transform: scale(0.8); }

.scale05 {
  -webkit-transform: scale(0.5);
          transform: scale(0.5); }

html,
body {
  font-family: "Roboto Mono", monospace;
  font-size: 14px;
  font-weight: 400;
  background-color: #000;
  min-width: 320px; }

label {
  color: aliceblue; }

.white1 {
  color: #e3f7fa; }

.yellow1 {
  color: #f5a623; }

.green1 {
  color: limegreen; }

.red1 {
  color: #e03d3d; }

.block {
  display: block; }

.none {
  display: none; }

.picker {
  padding: 5px;
  border-radius: 5px;
  background-color: #384652 !important;
  color: white !important; }
  .picker input {
    box-shadow: none;
    color: #e3f7fa;
    font-weight: bold; }
    .picker input:invalid {
      background-color: #e03d3d; }
  .picker .react-datetime-picker__clear-button {
    pointer-events: none;
    display: none; }

a, a:active, a:visited, a:focus {
  color: burlywood;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  outline: none; }

a:hover {
  color: #9e7641; }

button {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 5px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }
  button > label {
    cursor: pointer; }
  button.btnIcon {
    border-radius: 50%;
    background-color: #282b34;
    border: none;
    color: #97eff9;
    width: 32px;
    height: 32px;
    box-shadow: 0 2px 2px 0.5px rgba(0, 0, 0, 0.5), inset 0 1px 3px 0.5px #118ab8; }
    button.btnIcon svg {
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      opacity: 0.7; }
    @media (hover: hover) {
      button.btnIcon:hover svg {
        opacity: 1;
        -webkit-filter: drop-shadow(0px 0px 5px #118ab8);
                filter: drop-shadow(0px 0px 5px #118ab8); } }
    button.btnIcon:active {
      background-color: #1e1d23;
      box-shadow: 0 2px 2px 0.5px #393c43, inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5); }
      button.btnIcon:active svg {
        opacity: 1;
        color: #32a7b4;
        -webkit-filter: drop-shadow(0px 0px 0px #118ab8);
                filter: drop-shadow(0px 0px 0px #118ab8); }
    button.btnIcon i[fill="currentColor"] {
      display: flex !important;
      align-items: center;
      justify-content: center; }
  button.btnDefault {
    padding: 10px;
    border-radius: 5px;
    background-color: transparent;
    border: none;
    font-weight: normal;
    color: #f5a623;
    box-shadow: 0 0 1px 3px #111010, inset 0 1px 0px 1px #324b5e;
    -webkit-transition: box-shadow 0.1s ease, color 0.2s ease, text-shadow 0.2s ease, background-color 0.2s ease;
    transition: box-shadow 0.1s ease, color 0.2s ease, text-shadow 0.2s ease, background-color 0.2s ease;
    text-shadow: 0 0px 0px #ffc466; }
    @media (hover: hover) {
      button.btnDefault:hover {
        color: #ffc466;
        text-shadow: 0 0px 5px #ffc466; } }
    button.btnDefault:active, button.btnDefault.on {
      background-color: #1e1d23;
      color: #9b9b9b;
      text-shadow: 0 0px 0px #1e1d23;
      box-shadow: 0 2px 2px 0.5px #393c43, inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5); }
  button:disabled {
    pointer-events: none;
    opacity: 0.3; }
  button.btnRed {
    background-color: #ff0000;
    color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 0 1px 3px rgba(104, 6, 6, 0.5), inset 0 1px 0px 1px #ff6060, 0 0 10px 5px rgba(255, 0, 0, 0.5); }
    @media (hover: hover) {
      button.btnRed:hover {
        color: white;
        text-shadow: 0 0px 3px white;
        box-shadow: 0 0 1px 3px rgba(104, 6, 6, 0.5), inset 0 1px 0px 1px #ff6060, 0 0 10px 5px rgba(255, 55, 55, 0.7); } }
    button.btnRed:active {
      background-color: #1e1d23;
      color: #da4848;
      text-shadow: 0 0px 0px #da4848;
      box-shadow: 0 2px 2px 0.5px #393c43, inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5); }

input, textarea {
  position: relative;
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
  background-color: #1e1d23;
  color: #f5a623;
  resize: none;
  box-shadow: inset 0 2px 2px 0.5px rgba(0, 0, 0, 0.5), 0 2px 2px 0.5px #393c43; }
  input:disabled, textarea:disabled {
    opacity: 0.5;
    pointer-events: none; }

textarea {
  min-height: 100px; }

.combo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  position: relative;
  border-radius: 5px;
  background-color: #222a35;
  box-shadow: 0 0 1px 2px #111010, inset 0 1px 0px 1px #324b5e; }
  .combo.disabled {
    opacity: 0.8; }
  .combo > .head {
    position: relative;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .combo > .head input {
      padding: 4px; }
    .combo > .head button {
      padding: 2px;
      margin-left: 5px; }
  .combo > .body {
    position: relative;
    width: 100%;
    z-index: 100; }
    .combo > .body > .content {
      position: absolute;
      background-color: #222a35;
      min-height: 50px;
      max-height: 200px;
      min-width: 100%;
      top: 2px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: top;
      padding: 5px 0;
      box-shadow: 0 0 1px 3px #111010, inset 0 1px 0px 1px #324b5e; }
      .combo > .body > .content.off {
        display: none; }
      .combo > .body > .content > .item {
        margin: 2px 5px; }

.contest {
  background-color: #384652; }
  .contest > .content {
    max-width: 960px;
    margin: auto;
    background-color: #222a35;
    padding: 10px; }
    .contest > .content .important {
      font-size: 22px;
      line-height: 0px;
      color: #e03d3d; }
    .contest > .content > .dbs {
      border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
      padding-bottom: 10px; }
    .contest > .content > .contestCreate > .block,
    .contest > .content > .contestCreate > .time > .block {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px; }
      .contest > .content > .contestCreate > .block > label,
      .contest > .content > .contestCreate > .time > .block > label {
        display: block;
        margin-bottom: 5px; }
      .contest > .content > .contestCreate > .block > .gamesSelected,
      .contest > .content > .contestCreate > .time > .block > .gamesSelected {
        padding: 10px;
        background-color: #384652;
        border-radius: 5px; }
        .contest > .content > .contestCreate > .block > .gamesSelected label,
        .contest > .content > .contestCreate > .time > .block > .gamesSelected label {
          color: #ff7c7c; }
      .contest > .content > .contestCreate > .block > .hide,
      .contest > .content > .contestCreate > .time > .block > .hide {
        display: none; }
    .contest > .content > .contestCreate > .time > .block {
      margin-right: 10px; }
    .contest > .content > .contestList > .list {
      display: flex;
      flex-wrap: wrap; }
      .contest > .content > .contestList > .list > .contestItem {
        margin: 5px;
        background-color: #384652;
        border-radius: 5px;
        padding: 5px;
        box-shadow: 0 0 1px 3px #111010, inset 0 1px 0px 1px #324b5e; }
        .contest > .content > .contestList > .list > .contestItem > .caption {
          overflow: hidden;
          margin-bottom: 5px;
          display: block; }
        .contest > .content > .contestList > .list > .contestItem > .price {
          color: limegreen;
          margin-bottom: 5px; }
        .contest > .content > .contestList > .list > .contestItem > .img {
          height: 100px;
          width: 200px;
          background-size: cover;
          background-position: center;
          border-radius: 5px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          margin-bottom: 5px; }
        .contest > .content > .contestList > .list > .contestItem > .range {
          background-color: #222a35;
          padding: 5px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .contest > .content > .contestList > .list > .contestItem > .range > .time {
            font-size: 12px;
            color: #b6cfd3; }
            .contest > .content > .contestList > .list > .contestItem > .range > .time > u,
            .contest > .content > .contestList > .list > .contestItem > .range > .time > i {
              color: white; }

.login {
  -webkit-animation: 0.5s ease-out 0s 1 slideInFromTop;
          animation: 0.5s ease-out 0s 1 slideInFromTop;
  box-sizing: border-box;
  padding: 10px; }
  .login h3 {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
    text-align: center;
    padding-bottom: 10px; }
  .login .wrapper {
    padding: 20px;
    background-image: url("/bg4_128x128.png");
    box-shadow: 0 1px 1px 2px #111010, inset 0 1px 0px 1px #5a7d97, inset 0 0 70px 35px #201f25, 0 0px 500px 20px #95f4fe;
    border-radius: 10px; }
  .login label {
    display: block; }

.notFound {
  -webkit-animation: 0.5s ease-out 0s 1 slideInFromTop;
          animation: 0.5s ease-out 0s 1 slideInFromTop;
  box-sizing: border-box;
  padding: 10px;
  width: 100%; }
  .notFound .wrapper {
    padding: 20px;
    background-image: url("/bg4_128x128.png");
    box-shadow: 0 1px 1px 2px #111010, inset 0 1px 0px 1px #5a7d97, inset 0 0 70px 35px #201f25, 0 0px 500px 20px #95f4fe;
    border-radius: 10px; }
  .notFound label {
    display: block; }

.password {
  -webkit-animation: 0.5s ease-out 0s 1 slideInFromTop;
          animation: 0.5s ease-out 0s 1 slideInFromTop;
  box-sizing: border-box;
  padding: 10px;
  border-top: 1px dashed rgba(255, 255, 255, 0.3);
  background-color: #222a35; }
  .password h3 {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
    text-align: center;
    padding-bottom: 10px; }
  .password .wrapper {
    padding: 20px;
    background-image: url("/bg4_128x128.png");
    box-shadow: 0 1px 1px 2px #111010, inset 0 1px 0px 1px #5a7d97, inset 0 0 70px 35px #201f25;
    border-radius: 10px; }
  .password label {
    display: block; }

.command {
  background-color: #222a35; }
  .command > .content {
    padding: 10px;
    box-sizing: border-box; }
    .command > .content > *:not(:last-child) {
      margin-bottom: 10px; }
    .command > .content > *,
    .command > .content > * > *,
    .command > .content > * > * > * {
      box-sizing: border-box; }
    .command > .content .buttons {
      background-color: #222a35; }
      .command > .content .buttons > button.send {
        width: 100%;
        padding: 10px; }
        .command > .content .buttons > button.send > b {
          margin-left: 5px; }

.settings > .content {
  border-top: 1px dashed rgba(255, 255, 255, 0.3);
  padding: 10px;
  background-color: #222a35; }
  .settings > .content button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px; }
    .settings > .content button label {
      margin-bottom: 10px; }

.statsWrapper .dbs {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3); }

.statsWrapper .stats {
  position: relative; }
  .statsWrapper .stats > .loader {
    color: #e3f7fa;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.3); }
    .statsWrapper .stats > .loader h3 {
      display: block;
      font-weight: bold; }
  .statsWrapper .stats > .pages {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #222a35; }
    .statsWrapper .stats > .pages button {
      padding: 5px;
      margin: 5px; }
    .statsWrapper .stats > .pages .curPage {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      color: #e3f7fa;
      text-align: center;
      height: 100%;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
  .statsWrapper .stats > .filters {
    padding: 5px;
    background-color: #384652;
    border-bottom: 1px solid #333;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .statsWrapper .stats > .filters > .reload,
    .statsWrapper .stats > .filters > .coin {
      padding: 7px;
      margin-right: 10px; }
    .statsWrapper .stats > .filters > .combo {
      margin-right: 10px; }
  .statsWrapper .stats > .content {
    background-color: #e3f7fa;
    font-size: 12px;
    overflow-x: scroll; }
    .statsWrapper .stats > .content table {
      border-radius: 5px;
      table-layout: fixed; }
      .statsWrapper .stats > .content table.tbGeneral {
        min-width: 100%; }
      .statsWrapper .stats > .content table tr.title {
        background-color: #384652;
        color: white;
        font-weight: bold; }
      .statsWrapper .stats > .content table tr.sort {
        background-color: #384652 !important; }
        .statsWrapper .stats > .content table tr.sort button {
          width: 100%;
          padding: 2px; }
      .statsWrapper .stats > .content table tr.sortBy {
        background-color: #222a35 !important; }
        .statsWrapper .stats > .content table tr.sortBy td {
          text-align: left; }
          .statsWrapper .stats > .content table tr.sortBy td button {
            margin-left: 10px;
            padding: 2px; }
            .statsWrapper .stats > .content table tr.sortBy td button > label {
              margin-right: 5px; }
      .statsWrapper .stats > .content table tr:nth-child(even) {
        background-color: #b6cfd3; }
      .statsWrapper .stats > .content table tr.totals:nth-child(odd) {
        background-color: #afc5d6; }
      .statsWrapper .stats > .content table tr.totals:nth-child(even) {
        background-color: #9ab0c2; }
      .statsWrapper .stats > .content table tr.totals.games {
        background-color: #dfa298;
        border-bottom: 3px solid #05101a;
        border-top: 3px solid #05101a; }
      .statsWrapper .stats > .content table tr td {
        border-right: 1px solid #555;
        word-break: keep-all;
        padding: 5px;
        text-align: right; }
        .statsWrapper .stats > .content table tr td.td_day {
          width: 100px;
          font-weight: bold; }
        .statsWrapper .stats > .content table tr td.td_game {
          font-weight: bold; }
        .statsWrapper .stats > .content table tr td.td_cur {
          width: 30px;
          font-weight: bold; }
        .statsWrapper .stats > .content table tr td.td_rtp {
          width: 50px; }
        .statsWrapper .stats > .content table tr td.td_he {
          width: 50px; }
        .statsWrapper .stats > .content table tr td.td_variance {
          width: 70px; }
        .statsWrapper .stats > .content table tr td.td_betcount {
          width: 80px; }
        .statsWrapper .stats > .content table tr td.td_chargerate {
          width: 50px; }
        .statsWrapper .stats > .content table tr td.red {
          color: red;
          font-weight: bold; }
        .statsWrapper .stats > .content table tr td.green {
          color: #0f4b0f;
          font-weight: bold; }

.statsTrWrapper .dbs {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3); }

.statsTrWrapper .statsTr {
  position: relative; }
  .statsTrWrapper .statsTr > .loader {
    color: #e3f7fa;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.3); }
    .statsTrWrapper .statsTr > .loader h3 {
      display: block;
      font-weight: bold; }
  .statsTrWrapper .statsTr > .pages {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #222a35; }
    .statsTrWrapper .statsTr > .pages button {
      padding: 5px;
      margin: 5px; }
    .statsTrWrapper .statsTr > .pages .curPage {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      color: #e3f7fa;
      text-align: center;
      height: 100%;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
  .statsTrWrapper .statsTr > .filters {
    padding: 5px;
    background-color: #384652;
    border-bottom: 1px solid #333;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap; }
    .statsTrWrapper .statsTr > .filters > .reload,
    .statsTrWrapper .statsTr > .filters > .toggleBets,
    .statsTrWrapper .statsTr > .filters > .coin {
      padding: 7px;
      margin-right: 10px; }
    .statsTrWrapper .statsTr > .filters > .combo {
      margin-right: 10px; }
    .statsTrWrapper .statsTr > .filters .inpFilter {
      padding: 3px;
      width: 200px;
      margin-right: 10px; }
  .statsTrWrapper .statsTr > .content {
    background-color: #e3f7fa;
    font-size: 12px;
    overflow-x: scroll; }
    .statsTrWrapper .statsTr > .content table {
      border-radius: 5px;
      table-layout: fixed; }
      .statsTrWrapper .statsTr > .content table.tbGeneral {
        min-width: 100%; }
      .statsTrWrapper .statsTr > .content table tr.title {
        background-color: #384652;
        color: white;
        font-weight: bold; }
      .statsTrWrapper .statsTr > .content table tr.sort {
        background-color: #384652 !important; }
        .statsTrWrapper .statsTr > .content table tr.sort button {
          width: 100%;
          padding: 2px; }
      .statsTrWrapper .statsTr > .content table tr.sortBy {
        background-color: #222a35 !important; }
        .statsTrWrapper .statsTr > .content table tr.sortBy td {
          text-align: left; }
          .statsTrWrapper .statsTr > .content table tr.sortBy td button {
            margin-left: 10px;
            padding: 2px; }
            .statsTrWrapper .statsTr > .content table tr.sortBy td button > label {
              margin-right: 5px; }
      .statsTrWrapper .statsTr > .content table tr.row:nth-child(even) {
        background-color: #b6cfd3; }
      .statsTrWrapper .statsTr > .content table tr td {
        border-right: 1px solid #555;
        word-break: keep-all;
        padding: 5px;
        text-align: right; }
        .statsTrWrapper .statsTr > .content table tr td.td_view {
          width: 22px;
          background-color: #222a35; }
          .statsTrWrapper .statsTr > .content table tr td.td_view button {
            padding: 3px; }
        .statsTrWrapper .statsTr > .content table tr td.td_day {
          width: 83px;
          font-weight: bold; }
        .statsTrWrapper .statsTr > .content table tr td.td_game {
          font-weight: bold; }
        .statsTrWrapper .statsTr > .content table tr td.td_cur {
          width: 30px;
          font-weight: bold; }
        .statsTrWrapper .statsTr > .content table tr td.td_status, .statsTrWrapper .statsTr > .content table tr td.td_action, .statsTrWrapper .statsTr > .content table tr td.td_type {
          width: 50px; }
        .statsTrWrapper .statsTr > .content table tr td.red {
          color: red;
          font-weight: bold; }
        .statsTrWrapper .statsTr > .content table tr td.green {
          color: #0f4b0f;
          font-weight: bold; }

.topBar {
  display: fixed;
  position: relative;
  top: 0;
  width: 100%;
  padding: 10px;
  background-color: #222a35; }
  .topBar .menuWrapper {
    position: absolute;
    z-index: 1000;
    width: 100%;
    margin-left: -10px;
    bottom: 0;
    color: aliceblue; }
    .topBar .menuWrapper .topMenu {
      position: absolute;
      background-color: #384652;
      width: 100%;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      box-shadow: 0 10px 10px 0px #111010, inset 0 1px 0px 1px #324b5e; }
      .topBar .menuWrapper .topMenu > .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 53px; }
        .topBar .menuWrapper .topMenu > .content .menuItem {
          flex: 1 1;
          background-color: rgba(255, 255, 255, 0.05);
          margin: 4px 0; }
        .topBar .menuWrapper .topMenu > .content a, .topBar .menuWrapper .topMenu > .content label {
          cursor: pointer;
          color: aliceblue;
          font-weight: bold; }
        .topBar .menuWrapper .topMenu > .content a:hover, .topBar .menuWrapper .topMenu > .content a:hover label {
          color: #6aa3d1; }
      .topBar .menuWrapper .topMenu .btnHide {
        position: absolute;
        bottom: 10px;
        right: 10px; }
      .topBar .menuWrapper .topMenu.visible {
        min-height: 100px; }
      .topBar .menuWrapper .topMenu.hidden {
        min-height: 0px;
        pointer-events: none; }
        .topBar .menuWrapper .topMenu.hidden .btnHide, .topBar .menuWrapper .topMenu.hidden .content {
          display: none; }

.users {
  background-color: #384652; }
  .users label {
    display: block; }
  .users .usersList .entry {
    background-color: #1e1d23;
    color: bisque;
    border-radius: 5px; }
    .users .usersList .entry .role_admin {
      color: red; }

