.combo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  position: relative;
  border-radius: 5px;
	background-color: $darkBlue1;
	box-shadow: 0 0 1px 2px #111010, inset 0 1px 0px 1px #324b5e;
	&.disabled {
		opacity: 0.8;
	}
  > .head {
    position: relative;
    padding:3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    input {
      padding: 4px;
    }
    button {
      padding: 2px;
      margin-left: 5px;
    }
  }
  > .body {
    position: relative;
    width: 100%;
		z-index: 100;
		
    > .content {
      position: absolute;
      background-color: $darkBlue1;
			min-height: 50px;
			max-height: 200px;
      min-width: 100%;
      top: 2px;
      border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			overflow-y: scroll;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: top;
			padding: 5px 0;
			box-shadow: 0 0 1px 3px #111010, inset 0 1px 0px 1px #324b5e;
			&.off {
				display: none;
			}
			> .item {
				margin: 2px 5px;
			}
    }
  }
}
