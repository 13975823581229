// @import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:400,700");
div,
input,
textarea,
button,
label,
ul,
li,
span {
  box-sizing: border-box;
  font: inherit;
  // font-family: "Roboto", sans-serif;
  font-family: "Roboto Mono", monospace;
}
* {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
*:focus {
  outline: none;
}
.break_all {
  word-break: break-all;
}
.break_word {
  word-break: break-word;
}
.keep_all {
  word-break: keep-all;
}
.fade_30 {
  opacity: 0.3;
  pointer-events: none;
}
.fade_50 {
  opacity: 0.5;
  pointer-events: none;
}
.flex_h {
  display: flex;
  flex-direction: row;
}
.flex_v {
  display: flex;
  flex-direction: column;
}
.flex1 {
  flex: 1;
}
.flex_c_c {
  align-items: center;
  justify-content: center;
}
.flex_c_0 {
  align-items: center;
  justify-content: flex-start;
}
.flex_c_1 {
  align-items: center;
  justify-content: flex-end;
}
.flex_0_c {
  align-items: flex-start;
  justify-content: center;
}
.flex_0_0 {
  align-items: flex-start;
  justify-content: flex-start;
}
.flex_0_1 {
  align-items: flex-start;
  justify-content: flex-end;
}
.flex_1_c {
  align-items: flex-end;
  justify-content: center;
}
.flex_1_0 {
  align-items: flex-end;
  justify-content: flex-start;
}
.flex_1_1 {
  align-items: flex-end;
  justify-content: flex-end;
}
.flex_c_b {
  align-items: center;
  justify-content: space-between;
}
.flex_c_a {
  align-items: center;
  justify-content: space-around;
}
.flex_c_1_i {
  align-items: center !important;
  justify-content: flex-end !important;
}
.flex_wrap {
  flex-wrap: wrap;
}
.flex_a_0 {
  align-self: flex-start;
}
.flex_a_1 {
  align-self: flex-end;
}

.grid {
  display: grid;
}

.l {
  text-align: left;
}
.c {
  text-align: center;
}
.r {
  text-align: right;
}

h1,
h2,
h3,
h4,
h5 {
  padding: 0 0;
  margin: 0 0;
  text-transform: uppercase;
}

@for $i from 5 to 50 {
  .f#{$i} {
    font-size: #{$i}px;
  }
  .m#{$i}_all {
    margin: #{$i}px;
  }
  .m#{$i}_l {
    margin-left: #{$i}px;
  }
  .m#{$i}_r {
    margin-right: #{$i}px;
  }
  .m#{$i}_t {
    margin-top: #{$i}px;
  }
  .m#{$i}_b {
    margin-bottom: #{$i}px;
  }
  .p#{$i}_all {
    padding: #{$i}px;
  }
  .p#{$i}_l {
    padding-left: #{$i}px;
  }
  .p#{$i}_r {
    padding-right: #{$i}px;
  }
  .p#{$i}_t {
    padding-top: #{$i}px;
  }
  .p#{$i}_b {
    padding-bottom: #{$i}px;
  }
}
.h50 {
  height: 50%;
}
.w50 {
  width: 50%;
}
.h100 {
  height: 100%;
}
.w100 {
  width: 100%;
}
.w100vw {
  width: 100vw;
}
.h100vh {
  height: 100vh;
}
.w320px {
  width: 320px;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.scale08 {
  transform: scale(0.8);
}
.scale05 {
  transform: scale(0.5);
}

html,
body {
  // font-family: "Roboto", sans-serif;
  font-family: "Roboto Mono", monospace;
  font-size: 14px;
  font-weight: 400;
  background-color: #000;
  min-width: 320px;
}
label {
  color: aliceblue;
}
.white1 {
  color: $white;
}
.yellow1 {
  color: $yellow1;
}
.green1 {
  color: $green1;
}
.red1 {
  color: $red1;
}
.block {
	display: block;
}
.none {
	display: none;
}

.picker {
	padding: 5px;
	border-radius: 5px;
	background-color: $darkBlue2!important;
	color: white!important;
	input {
		box-shadow: none;
		color: $white;
		// color: rgb(0, 82, 158);
		font-weight: bold;
		&:invalid {
			background-color: $red1;
		}
	}
	.react-datetime-picker__clear-button {
		pointer-events: none;
		display: none;
	}
}