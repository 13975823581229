@import "./scss/params";
@import "./scss/animations";
@import "./scss/reset";
@import "./scss/general";

//components:
@import "./scss/button";
@import "./components/input/input";
@import "./components/combo/combo";
// //containers:
@import "./containers/contest/contest";
@import "./containers/login/login";
@import "./containers/main/main";
@import "./containers/notFound/notFound";
@import "./containers/password/password";
@import "./containers/command/command";
@import "./containers/settings/settings";
@import "./containers/stats/stats";
@import "./containers/statsTr/statsTr";
@import "./containers/topBar/topBar";
@import "./containers/users/users";
