.notFound {
  animation: 0.5s ease-out 0s 1 slideInFromTop;
  box-sizing: border-box;
	padding: 10px;
	width: 100%;
  .wrapper {
    padding: 20px;
    background-image: url("/bg4_128x128.png");
    box-shadow: 0 1px 1px 2px #111010, inset 0 1px 0px 1px #5a7d97, inset 0 0 70px 35px #201f25,
      0 0px 500px 20px #95f4fe;
    border-radius: 10px;
  }
  label {
    display: block;
  }
}
