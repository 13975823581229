.statsWrapper {
  .dbs {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
  }
  .stats {
    position: relative;
    > .loader {
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 50px;
      h3 {
        display: block;
        font-weight: bold;
      }
      border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
    }
    > .pages {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $darkBlue1;
      button {
        padding: 5px;
        margin: 5px;
      }
      .curPage {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        color: $white;
        text-align: center;
        height: 100%;
        user-select: none;
      }
    }
    > .filters {
      padding: 5px;
      background-color: $darkBlue2;
      border-bottom: 1px solid #333;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > .reload,
      > .coin {
        padding: 7px;
        margin-right: 10px;
      }
      > .combo {
        margin-right: 10px;
      }
    }
    > .content {
      background-color: $white;
      font-size: 12px;
      overflow-x: scroll;

      table {
        border-radius: 5px;
        table-layout: fixed;
        &.tbGeneral {
          min-width: 100%;
        }
        tr {
          &.title {
            background-color: $darkBlue2;
            color: white;
            font-weight: bold;
          }
          &.sort {
            background-color: $darkBlue2 !important;
            button {
              width: 100%;
              padding: 2px;
            }
          }
          &.sortBy {
            background-color: $darkBlue1 !important;
            td {
              text-align: left;
              button {
                > label {
                  margin-right: 5px;
                }
                margin-left: 10px;
                padding: 2px;
              }
            }
          }
          &:nth-child(even) {
            background-color: $white2;
          }
          &.totals {
            &:nth-child(odd) {
              background-color: rgb(175, 197, 214);
            }
            &:nth-child(even) {
              background-color: rgb(154, 176, 194);
            }
            &.games {
							background-color: rgb(223, 162, 152);
							border-bottom: 3px solid rgb(5, 16, 26);
							border-top: 3px solid rgb(5, 16, 26);
            }
          }
          td {
            border-right: 1px solid #555;
            word-break: keep-all;
            padding: 5px;
            &.td_day {
              width: 100px;
              font-weight: bold;
            }
            &.td_game {
              font-weight: bold;
            }
            &.td_cur {
              width: 30px;
              font-weight: bold;
            }
            &.td_rtp {
              width: 50px;
            }
            &.td_he {
              width: 50px;
            }
            &.td_variance {
              width: 70px;
            }
            &.td_betcount {
              width: 80px;
            }
            &.td_chargerate {
              width: 50px;
            }
            text-align: right;
            &.red {
              color: red;
              font-weight: bold;
            }
            &.green {
              color: rgb(15, 75, 15);
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
