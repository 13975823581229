.users {
  background-color: $darkBlue2;
  label {
    display: block;
  }
  .usersList {
    .entry {
			.role_admin {
				color: red;
			}
      background-color: #1e1d23;
      color: bisque;
      border-radius: 5px;
    }
  }
}
